<template>
  <page-view bg-color="#F8F8F8" v-show="!loading">
    <template v-slot:nav>
      <nav-bar v-if="appVersionInfo.isShowNavBar"
               title="小程序"
      >
      </nav-bar>
    </template>
    <div>
      <stepper :active="active" class="marginlr-25" />
      <div v-if="model=== 'attest'">
        <div class="card marginlr-25 main__form">
          <van-form ref="J_form">
            <van-field
              v-model="form.legal_persona_name"
              input-align="right"
              error-message-align="right"
              clearable
              name="personaName"
              label="法人姓名"
              placeholder="法人姓名（绑定银行卡）"
              :rules="[{ required: true, message: '请填写法人姓名（绑定银行卡）' }]"
            />
            <van-field
              v-model="form.legal_persona_wechat"
              input-align="right"
              error-message-align="right"
              clearable
              name="personaWeChat"
              label="法人微信号"
              placeholder="例如：FEI41829272"
              :rules="[{ required: true, message: '请填写法人微信' }]"
            />
            <van-field
              v-model="form.name"
              input-align="right"
              error-message-align="right"
              clearable
              name="name"
              label="企业名称"
              placeholder="需与工商部门登记信息一致"
              :rules="[{ required: true, message: '请填写企业名称' }]"
            />
            <van-field
              input-align="right"
              error-message-align="right"
              v-model="form.code"
              clearable
              name="code"
              label="企业代码"
              placeholder="例如：648210741M43829480"
              :rules="[{ required: true, message: '请填写企业代码' }]"
            />
            <van-field
              is-link
              input-align="right"
              error-message-align="right"
              readonly
              clickable
              :value="selectCodeType"
              @click="codeTypeDialog = true"
              name="code"
              label="企业代码类型"
              placeholder="点击选择企业代码类型"
              :rules="[{ required: true, message: '请填写选择企业代码类型' }]"
            />
          </van-form>
        </div>
        <div class="main__desc marginlr-25">
          <p>企业代码类型</p>
          <p>1、统一社会信用代码（18 位）</p>
          <p>2、组织机构代码（9 位 xxxxxxxx-x）</p>
          <p>3、营业执照注册号(15 位)</p>
        </div>
      </div>
      <!-- 结果页面 -->
      <div v-else>
        <status :desc="failMessage" :model="resultType" />
      </div>
    </div>
    <template v-slot:footer>
      <van-button v-if="model=== 'attest'" class="footer-btn-group" @click="hanldeSubmit" type="primary" block>提交</van-button>
    </template>
    <template v-slot:other>
      <!-- 弹出选择 -->
      <van-popup v-model="codeTypeDialog" position="bottom">
        <van-picker
          show-toolbar
          value-key="label"
          :columns="codeTypeList"
          @confirm="handleConfirm"
          @cancel="codeTypeDialog = false"
        />
      </van-popup>
    </template>
  </page-view>
</template>
<script>
import appVersionMixins from '@/minxins/app-version-mixins'
import decounce from 'lodash.debounce'
import Stepper from './modules/stepper'
import Status from './modules/status'
import PageView from '@/layouts/PageView'
import NavBar from '@/components/nav-bar'
import { Form, Field, Picker, Button, Toast, Popup } from 'vant'
import AttestMiniprogramModel from '../../model/attest-miniprogram/index'
export default {
  name: 'AttestMiniprogram',
  mixins: [appVersionMixins],
  components: {
    PageView,
    Stepper,
    Status,
    NavBar,
    [Form.name]: Form,
    [Field.name]: Field,
    [Picker.name]: Picker,
    [Button.name]: Button,
    [Toast.name]: Toast,
    [Popup.name]: Popup
  },
  props: {},
  data () {
    return {
      loading: true,
      active: 0,
      resultType: '',
      failMessage: '',
      codeTypeDialog: false,
      codeTypeList: [
        {
          label: '统一社会信用代码',
          value: '1'
        },
        {
          label: '组织机构代码',
          value: '2'
        },
        {
          label: '营业执照注册号',
          value: '3'
        }
      ],
      selectCodeType: '统一社会信用代码',
      form: {
        name: null,
        code: null,
        code_type: '1',
        legal_persona_wechat: null,
        legal_persona_name: null
      }
    }
  },
  computed: {
    model () {
      if (this.active === 0) {
        return 'attest'
      } else if (this.active === 1) {
        return 'result'
      }
      return ''
    }
  },
  watch: {},
  async created () {
    this.loading = true
    // 获取初始化状态
    const json = await AttestMiniprogramModel.getRegisterStatus()
    const { result, data } = json
    if (result) {
      const { state, message } = data
      if (state === 1) { // 审核中
        this.active = 1
        this.resultType = 'review'
      } else if (state === 3) {
        this.active = 1
        this.resultType = 'fail'
        this.failMessage = message
      } else if (state === 2) { // 审核成功
        // TODO 跳转到创建小程序详情页面
        setTimeout(this.$router.push({
          name: 'minProgramVerify'
        }), 1000)
      }
    }
    this.loading = false
  },
  mounted () {},
  destroyed () {},
  methods: {
    /**
     * 提交表单
     */
    hanldeSubmit: decounce(function () {
      this.$refs.J_form.validate().then(async _ => {
        const json = await AttestMiniprogramModel.submitRegisterInfo(this.form)
        if (json.result) {
          // 提交通过
          this.active = 1
          this.resultType = 'review'
        } else if (json.message.length) {
          Toast(json.message)
        }
      }).catch(_ => {
      })
    }, 500),

    handleReset () {
      this.active = 0
      this.resultType = ''
    },
    // 确认提交
    handleConfirm (obj) {
      this.form.code_type = obj.value
      this.selectCodeType = obj.label
      this.codeTypeDialog = false
    }
  }
}
</script>
<style lang="scss" scoped>
[v-cloak] {
  display: none;
}
@import "./index.scss";
</style>

<template>
  <div class="status" :class="[model === 'review' ? 'status--review': '']">
    <!-- 审核模式 -->
    <div v-if="model === 'review'">
      <div class="status__desc status__img-box desc-box" style="margin-bottom: 30px;">
        <p class="img__title">
          <span class="title__num van-hairline--surround">01</span>
          提交成功，正在审核！
        </p>
        <div class="desc">请注意微信平台向法人微信下发的模板消息。
          法人需在24小时内点击消息，进行身份证信息与人脸识别信息收集</div>
      </div>
      <div class="status__img-box">
        <p class="img__title">
          <span class="title__num van-hairline--surround">02</span>
          认证小程序授权
        </p>
        <img class="status__img" src="../../../assets/images/attest-miniprogram/tip.png" alt="">
        <p>登陆生意专家电脑端</p>
        <p>进行公众平台账号授权操作</p>
        <p class="link">www.shengyi.ai</p>
      </div>
    </div>
    <div v-else>
      <div class="status__top">
        <div class="iconfont2  status__icon" :class="iconClass"></div>
        <div class="status__title">{{ title }}</div>
      </div>
      <div class="status__desc" v-html="showDesc">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AttestStatus',
  mixins: [],
  components: {},
  props: {
    model: { // review fail
      type: String,
      default: 'review'
    },
    desc: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
    }
  },
  computed: {
    title () {
      if (this.model === 'review') {
        return '审核中'
      } else {
        return '审核失败'
      }
    },
    showDesc () {
      if (this.model === 'review') {
        return '<p>请注意微信平台向法人微信下发的模板消息。</p><p>法人需在24小时内点击消息，进行身份证信息与人脸识别信息收集</p>'
      } else if (this.model === 'fail') {
        return `<p>${this.desc}</p><p class="margint-20">如有疑问请联系：400-168-3399</p>`
      }
      return ''
    },
    iconClass () {
      if (this.model === 'review') {
        return 'icon-shijian'
      } else if (this.model === 'fail') {
        return 'icon-shibai status__icon--warning'
      }
      return ''
    }
  },
  watch: {},
  created () { },
  mounted () { },
  destroyed () { },
  methods: {}
}
</script>
<style lang="scss" scoped>
@import "../../../../src/assets/scss/base/_mixin";
@import "../../../../src/assets/scss/base/config";
  .status {
    margin-top: px2rem(120px);
    &--review {
      margin-top: px2rem(60px);
    }
    &__top {
      display: flex;
      justify-content: center;
      flex-direction:column;
      align-items: center;
    }

    &__img-box {
      margin: 0 auto;
      overflow: hidden;
      width: px2rem(700px);
      height: px2rem(699px);
      background:rgba(255,255,255,1);
      border-radius:8px;
      >p {
        font-size: px2rem(30px);
        color:#999999;
        text-align: center;
        margin-bottom: px2rem(10px);
      }
    }

    &__img {
      margin: px2rem(40px) px2rem(60px) px2rem(20px) px2rem(60px);
      width: px2rem(600px);
      height: px2rem(342px);
    }
    &__icon {
      font-size: px2rem(110px);
      color:#FF6A3C;
      &--warning {
        color:#E3AB29;
      }
    }
    &__title {
      font-size: px2rem(36px);
    }

    &__desc {
      text-align: center;
      width: px2rem(650px);
      margin: px2rem(50px) auto 0 auto;
      font-size: px2rem(30px);
      color:#999999;
    }
  }

  .margint-20 {
    margin-top: px2rem(20px);
  }

  .status__img-box >.img__title {
    position: relative;
    margin-top: px2rem(54px);
    color: #666666;
    font-size: px2rem(30px);
    text-align: center;
  }

  .title__num {
    line-height: 1;
    position: absolute;
    display: inline-block;
    padding: px2rem(8px) px2rem(15px);
    left: px2rem(60px);
    color:#17BF62;
    font-weight: normal;
    &::after {
      border-color:#17BF62;
      border-radius:25px;
    }
  }

  .status__img-box >.link {
    font-size: px2rem(24px);
  }

  .desc-box {
    width: px2rem(700px);
    height: auto;
  }

  .desc {
    text-align: left;
    margin: px2rem(30px) px2rem(50px);
  }
</style>
